@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.add-to-cart-overlay {
  :global(.modal-header) {
    padding-top: 13px !important;
    padding-right: 13px;
  }
  :global(.modal-title) {
    margin: 0 auto;
  }
  :global(.modal-header .btn-close) {
    margin: 0;
    font-size: calc(1rem + 0.3vw);
    opacity: 0.9;

    @include media-breakpoint-up(xl) {
      font-size: 1.2rem;
    }
  }

  & .content {
    display: grid;
    gap: rfs-value(15px);
    align-items: center;
    justify-content: center;
    grid-template-columns: 200px 1fr;
    border-top: 1px solid $nikon-light-grey;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }

    & .photo-container {
      display: flex;
      align-items: flex-start;
      height: 100%;

      @include media-breakpoint-down(sm) {
        height: auto;
        max-width: 150px;
        margin: 0 auto;
      }
    }

    & .name {
      text-decoration: none;
      color: $body-color;
      font-weight: bold;
      line-height: rfs-value(18px);
      font-size: rfs-value(18px);

      @include media-breakpoint-down(sm) {
        font-size: rfs-value(16px);
        display: block;
        text-align: center !important;
        margin-bottom: 0;
      }
    }

    & .sku {
      color: $body-color;
      font-size: rfs-value(13px);

      @include media-breakpoint-down(sm) {
        font-size: rfs-value(11px);
        display: block;
        text-align: center !important;
        margin-bottom: 0;
      }
    }

    & .currentPrice {
      font-weight: bold;
      font-size: rfs-value(18px);

      @include media-breakpoint-down(sm) {
        font-size: rfs-value(16px);
        display: block;
        text-align: center !important;
        margin-bottom: 0;
      }
    }
  }
  .subTotalContainer {
    margin-top: 15px;
    padding-top: 20px;
    border-top: 1px solid $nikon-light-grey;

    & .subTotal {
      text-decoration: none;
      color: $body-color;
      font-weight: bold;
      line-height: rfs-value(18px);
      font-size: rfs-value(18px);

      @include media-breakpoint-down(sm) {
        font-size: rfs-value(16px);
      }
    }
  }
}
