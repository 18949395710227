@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.listItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tileBody {
  padding: 15px;
}

.hoverBody {
  &:hover {
    border: 2px solid;
    padding: 13px;
  }
}

.borderAdd {
  border: 2px solid;
}

.addToCart {
  margin: 0 auto;
  text-align: center;


  @include media-breakpoint-down(sm) {
    :global(.btn-yellow) {
      font-size: rfs-value(14px);
    }
  }
}

.currentPrice {
  font-weight: bold;
  font-size: rfs-value(18px);

  @include media-breakpoint-down(sm) {
    font-size: rfs-value(16px);
    display: block;
    margin-bottom: 0;
  }
}

.max-quantity-meet {
  line-height: rfs-value(22px);
  font-size: rfs-value(14px);
  font-style: italic;
  color: $nikon-dark-grey;
}
