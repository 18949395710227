@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.productSliderTitle {
  @include media-breakpoint-down(sm) {
    font-size: rfs-value(16px);
  }
}

.sliderContainer {
  @include media-breakpoint-down(sm) {
    :global(.slick-arrow) {
      top: 108% !important;
    }

    :global(.slick-prev) {
      left: 37% !important;
    }

    :global(.slick-next) {
      right: 37% !important;
    }
  }
  @media (max-width: 374px) {
    :global(.slick-prev) {
      left: 32% !important;
    }

    :global(.slick-next) {
      right: 32% !important;
    }
  }

  :global(.slick-arrow) {
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    width: 21px;

    &:focus,
    &:hover {
      opacity: 0.75;
    }
    @include media-breakpoint-up(md) {
      width: 35px;
    }
  }
  :global(.slick-prev) {
    border-radius: 50%;
    width: rfs-value(45px);
    height: rfs-value(45px);
    left: -45px;

    &::before {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
      font-size: rfs-value(22px);
      color: transparent;
    }
  }

  :global(.slick-next) {
    border-radius: 50%;
    width: rfs-value(45px);
    height: rfs-value(45px);
    right: -45px;

    &::before {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
      font-size: rfs-value(22px);
      color: transparent;
    }
  }

  :global(.btn-yellow) {
    margin: 0 auto;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: rfs-value(14px);
    }
    @media (max-width: 375px) {
      font-size: rfs-value(10px) !important;
    }

    @media (max-width: 374px) {
      font-size: rfs-value(6px) !important;
    }
  }
}
